import React from 'react';
import styled from 'styled-components';
import Img from "gatsby-image"
import { graphql, useStaticQuery } from 'gatsby';
import Layout from './layout';
import Seo from './seo';

const Section = styled.section`
  padding: 20px;
  background: #fff;
  @media screen and (max-width: 480px) {
    padding: 10px;
  }
`

const TitleBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const Title = styled.h2`
  color: #111;
  font-size: 2em;
  font-weight: 300;
  @media screen and (max-width: 920px) {
    font-size: 1.8em;
    line-height: 1.5em;
    margin-bottom: 15px;
    font-weight: 300;
  }
`

const GoldSpan = styled.span`
  color: gold;
  font-weight: 700;
  font-size: 1.5em;
`

const GalleryContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`

const ImgLink = styled.a`
  text-decoration: none;
`

const ImageBox = styled.div`
  position:relative;
  width: 30vw;
  margin: 15px;
  border: 15px solid #fff;
  box-shadow: 0 5px 35px rgba(0, 0, 0, 0.89);
  @media screen and (max-width: 920px) {
    width: 40vw;
    margin: 10px;
    border: 5px solid #fff;
  }
  @media screen and (max-width: 480px) {
    width: 40vw;
    margin: 10px;
    border: 5px solid #fff;
  }
`

const Images = styled(Img)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const Altanki = () => {
  const data = useStaticQuery(graphql`
  query {
    allFile(
      
      filter: {
        extension: { regex: "/(jpg)/" }
        name: { nin: ["background", "background2"], regex: "/[0-9]{1}(A)/" }
      }
    ) 
    
    {
      edges {
        node {
          base
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
  `)

  const sortedImages = data.allFile.edges.slice().sort((a, b) => {
    return a.node.base.localeCompare(b.node.base);
  });


  return(
    <Layout>
      <Seo title="Altanki"/>
      <Section>
        <TitleBox>
          <Title><GoldSpan>A</GoldSpan>ltanki i domki</Title>
        </TitleBox>
        <GalleryContainer>
        {sortedImages.map((image, key) => (
          <ImgLink href={image.node.childImageSharp.fluid.src} key={key}>
          <ImageBox>
          <Images
            fluid={image.node.childImageSharp.fluid}
            alt={"Altanki i domki"}
          />
          </ImageBox>
          </ImgLink>
        ))}
        </GalleryContainer>
      </Section>
    </Layout>
  ) 
};

export default Altanki;

